import config from './config';
import log from './log';
import { getCookie, setCookie } from "./cookie";
import { getIframeHeight, domainValidation } from './utils';
import { sendMessage } from './iframe-communication';
import * as utils from './utils';

export let iframe;
export let iframeWrapper;

let isMobile = false;
let iframeIsTriggered = false;

export function triggerIframeCreation() {

    createIframe();
    createBackDrop();
    if (utils.deviceIsMobile()) {
        log.debug('Device is mobile');
        isMobile = true;
    }
    displayRegistrationWall(config.position);
    updateIframeSize();
}

/**
 * Create iframe and give it basic atributes.
 */
export function createIframe() {

    iframe = document.createElement('iframe');
    iframe.setAttribute('id', config.registrationWallId);
    iframe.setAttribute('class', 'register-iframe');

    if (process.env.NODE_ENV === 'local') {
        iframe.src = 'http://localhost:4200/';
    } else {
        iframe.src = `https://${domainValidation(config.publisherSiteId)}${process.env.ROOT_DOMAIN_NAME.replace(/\//g, '')}/index.html`;
    }
}

/**
 * Update iframe size with the size we received in configuration.
 */
export function updateIframeSize() {
    log.debug('Updating screen size!');
    if (isMobile === true && config.iframeConfig.mobileConfig.matchDesktop === false) {
        iframe.style.height = getIframeHeight(config.iframeConfig.mobileStyle.header.height, config.iframeConfig.mobileStyle.body.height);
        iframe.style.width = config.iframeConfig.mobileStyle.body.width;
    } else {
        iframe.style.height = getIframeHeight(config.iframeConfig.desktopStyle.header.height, config.iframeConfig.desktopStyle.body.height);
        iframe.style.width = config.iframeConfig.desktopStyle.body.width;
    }
    iframe.style.cssText += 'box-sizing: initial;';
}

/**
 * Once application is configured, iframe should be displayed.
 */
export function setIframeDisplay() {

    if (!iframeIsTriggered) {
        if (utils.userInformation.isLoggedIn) {
            unhideIframe();
        } else if (config.showIframeOptions === 'interval') {
            showIframeAfterInterval();
        } else if (config.showIframeOptions === 'siteVisits') {
            showIframeAfterAmountOfVisits();
        } else {
            unhideIframe();
        }
    } else {
        unhideIframe();
    }

}

function showIframeAfterInterval() {
    if (config.showIframeInterval) {
        setTimeout(() => {
            unhideIframe();
        }, config.showIframeInterval);
    } else {
        log.debug("You need to define 'showIframeInterval' property");
    }
}

function showIframeAfterAmountOfVisits() {
    if (config.showIframeAmountSiteVisits) {
        if (amountOfVisits() >= config.showIframeAmountSiteVisits) {
            unhideIframe();
        }
    } else {
        log.debug("You need to define 'showIframeAmountSiteVisits' property");
    }
}

function unhideIframe() {
    iframe.style.display = '';
    iframeWrapper.style.display = 'flex';
    iframeIsTriggered = true;
}

/**
 * Checks where should should we position iframe.
 * @param {String} position
 */
export function displayRegistrationWall(position) {

    if (isMobile === true) {
        position = 'modal';
        displayAsModal();
    } else if (position === 'corner') {
        displayInCorner(config.cornerPosition);
    } else if (position === 'top' || position === 'topRight' || position === 'topLeft') {
        displayOnTop();
    } else if (position === 'bottom' || position === 'bottomRight' || position === 'bottomLeft') {
        displayAtBottom();
    } else {
        displayAsModal();
    }
    setConsentToolPosition(position);
}

/**
 * Display iframe at the bottom of the page.
 */
function displayAtBottom() {

    if (config.showBackdrop) { showBackdrop(); }

    log.debug('Displaying wall on the bottom!');
    iframeWrapper.style.cssText += 'position: fixed; bottom: 0; right: 0; left: 0;z-index: 500;justify-content:center;align-items:flex-end;';
    iframe.style.cssText = 'width: 500px; height: 400px; bottom: 0; left: 0;display:none;border: 2px solid rgba(0,0,0,.14);border-radius: 5px;';
    document.body.appendChild(iframeWrapper, document.body.firstElementChild);
}

/**
 * Display iframe at the top of the page.
 */
function displayOnTop() {

    if (config.showBackdrop) { showBackdrop(); }

    log.debug('Displaying wall in top!');
    iframeWrapper.style.cssText += 'position: fixed; top: 0; right: 0; left: 0;z-index: 500;justify-content:center;';
    iframe.style.cssText = 'top:0;left:0;display:none;border: 2px solid rgba(0,0,0,.14);border-radius:5px;background-color:white;position:static;';
    document.body.insertBefore(iframeWrapper, document.body.firstElementChild);
}

function displayAsModal() {

    if (config.showBackdrop) { showBackdrop(); }

    log.debug('Displaying wall as modal!');

    if (isMobile === true && config.iframeConfig.mobileConfig.matchDesktop === false) {
        iframe.style.cssText = 'left:50%;top:50%;transform:translate(-50%, -50%);-webkit-transform:translate(-50%, -50%);width:0px;min-width: 10%;display:none;border: 2px solid rgba(0,0,0,.14);border-radius: 5px;';
    } else {
        iframe.style.cssText = 'left:50%;top:50%;transform:translate(-50%, -50%);-webkit-transform:translate(-50%, -50%);width:1000px; max-width: 100%;display:none;border: 2px solid rgba(0,0,0,.14);border-radius: 5px;';
    }
    document.body.insertBefore(iframeWrapper, document.body.firstElementChild);
}

function displayInCorner(position) {

    if (config.showBackdrop) { showBackdrop(); }

    let iframeCss = "max-width:calc(100% - 100px); width: 50%;display:none; border: 2px solid rgba(0,0,0,.14);border-radius: 5px;";

    switch (position) {
        case 'top-left':
            log.debug('Displaying wall in top-left corner!');
            iframeCss += "top: 50px; left: 50px;";
            break;
        case 'top-right':
            log.debug('Displaying wall in top-right corner!');
            iframeCss += "top: 50px; right: 50px;";
            break;
        case 'bottom-left':
            log.debug('Displaying wall in bottom-left corner!');
            iframeCss = iframeCss + "bottom: 50px; left: 50px;";
            break;
        case 'bottom-right':
            log.debug('Displaying wall in top-right corner!');
            iframeCss += "bottom: 50px; right: 50px;";
            break;
    }

    iframe.style.cssText = iframeCss;
    document.body.insertBefore(iframeWrapper, document.body.firstElementChild);
}

/**
 * Show light-gray background behind the iframe.
 */
function showBackdrop() {
    iframeWrapper.style.cssText = 'position: fixed; width: 100%; height: 100%; top: 0; left: 0; z-index: 2147483647;display:none;border: 2px solid rgba(0,0,0,.14);border-radius: 5px;';
    iframeWrapper.style.background = 'rgba(0,0,0,0.5)';
    iframeWrapper.appendChild(iframe);
}

function setConsentToolPosition(position) {
    if (position !== 'top' && position !== 'bottom') {
        iframe.style.position = 'fixed';
    }

    // Set max z-index
    iframe.style.zIndex = 2147483647;
};

/**
 * Create backdrop for the iframe and append it as it's child.
 */
function createBackDrop() {
    iframeWrapper = document.createElement('div');
    iframeWrapper.setAttribute('id', config.registrationWallWrapperId);
    iframeWrapper.appendChild(iframe);
}

export function amountOfVisits() {
    let count = getCookie('lr_visit_site_amount');
    if (count === '') {
        setCookie('lr_visit_site_amount', 1);
        return 1
    } else {
        let newCount = parseInt(count) + 1;
        setCookie('lr_visit_site_amount', newCount);
        return newCount
    }
}

/**
 * Destroy iframe and it's parent.
 */
export function destroyIframe() {
    let elem = document.getElementById(config.registrationWallWrapperId);
    elem.parentNode.removeChild(elem);
}

export function hideIframe() {
    let elem = document.getElementById(config.registrationWallWrapperId);
    elem.style.display = "none";
}

export function showIframe() {
    // send message ti app to change root to menu
    sendMessage("show-self-service-menu", {});
}
