let config = {};

config.generate = function (version = 1) {
	return {
		'logging': undefined,
		'publisherId': -1,
		'registrationWallId': 'register-iframe',
		'registrationWallWrapperId': 'register-iframe-wrapper',
		'position': 'modal',
		'cornerPosition': 'top-left',
		'publisherSiteId': '',
		'showIframe': true,
		'showBackdrop': true,
		'activeThresholdPercentage': 100,
		//array of browsers(strings) on which library will be triggered
		'browsers': undefined,
		'iframeConfig': {
			'mobileConfig': {
				'matchDesktop': true
			},
			'awsConfig': {
				"aws_user_pools_id": undefined,
				"aws_user_pools_web_client_id": undefined,
				"domain": undefined,
				'social_identity_providers': {
					'signInWithFacebook': false,
					'signInWithGoogle': false,
					'signInWithApple': false
				}
			},
			"consentString": '',
			'passwordPolicy': {
				'showPasswordRequirements': false,
				'passwordRequireNumbers': true,
				'passwordRequireLowercase': true,
				'passwordRequireSymbols': false,
				'passwordRequireUppercase': true,
				'passwordMinimumLength': 8,
				'style': {
					'fontSize': '14px',
					'defaultColor': '#bcbcbc',
					'passwordNotMeetColor': 'red'
				}
			},
			'privacyPolicy': {
				'textContent': '',
				'hrefValue': ''
			},
			'recaptcha': {
				'theme': 'light',
				'useRecaptcha': false
			},
			'regWallTokenExpirationPeriodInDays': 30,
			'desktopStyle': {
				'body': {
					'backgroundColor': '',
					'closable': true,
					'fontFamily': "Roboto;Arial;sans-serif",
					'height': '350px',
					'width': '360px'
				},
				'header': {
					'backgroundColor': 'black',
					'height': '210px',
					'logoUrl': 'https://storage.googleapis.com/openlogin-assets/FVE.png',
					'closeButton': {
						'color': ''
					}
				},
				'submitButton': {
					'backgroundColor': 'black',
					'fontSize': '17px',
					'height': '',
					'color': 'white',
					'width': ''
				},
				'registrationStep': {
					'registerButtonText': "Sign Up",
					'registrationInput': {
						'placeholderUser': 'Email or Phone',
						'placeholderPassword': 'Enter Password',
						'placeholderRepeatPassword': 'Repeat password',
						'enableCountryCodesDropdown': true
					},
					'title': {
						'textContent': "Help support quality content.",
						'color': "white",
						'fontSize': "36px",
					},
					'subtitle': {
						'textContent': "Sign up or login.",
						'color': "white",
						'fontSize': "14px"
					},
					'continueToLoginLink': {
						'textContent': 'Login',
						'fontSize': '14px',
						'color': 'black'
					}
				},
				'loginStep': {
					'loginButtonText': 'Submit',
					'loginInput': {
						'placeholderUser': 'Email or phone',
						'placeholderPassword': 'Password',
						'enableCountryCodesDropdown': true
					},
					'title': {
						'textContent': "Login",
						'color': "#FFFFFF",
						'fontSize': "36px",
					},
					'subtitle': {
						'textContent': "Enter your username and password.",
						'color': "#FFFFFF",
						'fontSize': "14px"
					},
					'backToRegister': {
						'textContent': 'Back to sign up',
						'fontSize': '14px',
						'color': '#000'
					},
					'forgotPasswordLink': {
						'textContent': 'Forgot password?',
						'fontSize': '14px',
						'color': '#000'
					}
				},
				'forgotPasswordStep': {
					'forgotPasswordInput': {
						'usernamePlaceholder': 'Enter your email or phone number',
						'newPasswordPlaceholder': 'Enter new password',
						'verificationCodePlaceholder': 'Enter verification code',
						'placeholderRepeatPassword': 'Repeat password'
					},
					'title': {
						'textContent': 'Forgot password',
						'color': 'white',
						'fontSize': '14px'
					},
					'subtitle': {
						'textContent': '',
						'color': 'white',
						'fontSize': '14px'
					},
					'backToLogin': {
						'textContent': 'Back',
						'fontSize': '14px',
						'color': '#000'
					}
				},
				'verificationStep': {

					'resendCodeLink': {
						'textContent': 'Resend verification code',
						'fontSize': '14px',
						'color': 'black'
					},
					'title': {
						'textContent': "Please confirm your information.",
						'color': "#FFFFFF",
						'fontSize': "18px",
					},
					'subtitle': {
						'textContent': "Check your messages for the verification code.",
						'color': "#FFFFFF",
						'fontSize': "14px"
					},
					'backToRegister': {
						'textContent': 'Back to sign up',
						'fontSize': '14px',
						'color': '#000'
					}
				},
				'selfServiceMenuStep': {
					'title': {
						'textContent': "Manage Account",
						'color': "#FFFFFF",
						'fontSize': "36px",
					},
					'subtitle': {
						'textContent': "",
						'color': "#FFFFFF",
						'fontSize': "14px"
					},
					'selfServiceButtons': {
						'backgroundColor': 'black',
						'fontSize': '18px',
						'height': '',
						'color': 'white',
						'width': '170px'
					},
					'changePasswordButtonText': 'Update Password',
					'deleteAccountButtonText': 'Delete Account',
					'logoutButtonText': 'Logout',
				},
				'updatePasswordStep': {
					'updatePasswordButtonText': 'Change Password',
					'updatePasswordInput': {
						'placeholderOldPassword': 'Old Password',
						'placeholderNewPassword': 'New Password',
						'placeholderRepeatPassword': 'Repeat password'
					},
					'title': {
						'textContent': 'Update Password',
						'color': '#fff',
						'fontSize': '36px'
					},
					'subtitle': {
						'textContent': '',
						'color': '#fff',
						'fontSize': '14px'
					},
					'backToMenuLink': {
						'textContent': 'Back',
						'fontSize': '14px',
						'color': '#000'
					},
				},
				'localStorageBlockedPage': {
					'buttonText': 'Okay',
					'title': {
						'textContent': 'Registration Wall is not working.',
						'color': 'white',
						'fontSize': '36px',
					},
					'subtitle': {
						'textContent': 'Please enable third party cookies',
						'color': 'white',
						'fontSize': '14px'
					}
				}
			},
			'mobileStyle': {
				'body': {
					'backgroundColor': '',
					'closable': true,
					'fontFamily': "Roboto;Arial;sans-serif",
					'height': '360px',
					'width': '350px'
				},
				'header': {
					'backgroundColor': 'black',
					'height': '210px',
					'logoUrl': 'https://storage.googleapis.com/openlogin-assets/FVE.png',
					'closeButton': {
						'color': ''
					}
				},
				'submitButton': {
					'backgroundColor': 'black',
					'fontSize': '17px',
					'height': '',
					'color': 'white',
					'width': ''
				},
				'registrationStep': {
					'registerButtonText': "Sign Up",
					'registrationInput': {
						'placeholderUser': 'Email or Phone',
						'placeholderPassword': 'Enter Password',
						'placeholderRepeatPassword': 'Repeat password',
						'enableCountryCodesDropdown': true
					},
					'title': {
						'textContent': "Help support quality content.",
						'color': "white",
						'fontSize': "36px",
					},
					'subtitle': {
						'textContent': "Sign up or login.",
						'color': "white",
						'fontSize': "14px"
					},
					'continueToLoginLink': {
						'textContent': 'Login',
						'fontSize': '14px',
						'color': 'black'
					},
				},
				'loginStep': {
					'loginButtonText': 'Submit',
					'loginInput': {
						'placeholderUser': 'Email or phone',
						'placeholderPassword': 'Password',
						'enableCountryCodesDropdown': true
					},
					'title': {
						'textContent': "Login",
						'color': "#FFFFFF",
						'fontSize': "36px",
					},
					'subtitle': {
						'textContent': "Enter your username and password.",
						'color': "#FFFFFF",
						'fontSize': "14px"
					},
					'backToRegister': {
						'textContent': 'Back to register',
						'fontSize': '14px',
						'color': '#000'
					},
					'forgotPasswordLink': {
						'textContent': 'Forgot password?',
						'fontSize': '14px',
						'color': '#000'
					}
				},
				'forgotPasswordStep': {
					'forgotPasswordInput': {
						'usernamePlaceholder': 'Enter your email or phone number',
						'newPasswordPlaceholder': 'Enter new password',
						'verificationCodePlaceholder': 'Enter verification code',
						'placeholderRepeatPassword': 'Repeat password'
					},
					'title': {
						'textContent': 'Forgot password',
						'color': 'white',
						'fontSize': '14px'
					},
					'subtitle': {
						'textContent': '',
						'color': 'white',
						'fontSize': '14px'
					},
					'backToLogin': {
						'textContent': 'Back',
						'fontSize': '14px',
						'color': '#000'
					}
				},
				'verificationStep': {
					'resendCodeLink': {
						'textContent': 'Resend verification code',
						'fontSize': '14px',
						'color': 'black'
					},
					'title': {
						'textContent': "Please confirm your information.",
						'color': "#FFFFFF",
						'fontSize': "18px",
					},
					'subtitle': {
						'textContent': "Check your messages for the verification code.",
						'color': "#FFFFFF",
						'fontSize': "14px"
					},
					'backToRegister': {
						'textContent': 'Back to sign up',
						'fontSize': '14px',
						'color': '#000'
					}
				},
				'selfServiceMenuStep': {
					'title': {
						'textContent': "Manage Account",
						'color': "#FFFFFF",
						'fontSize': "36px",
					},
					'subtitle': {
						'textContent': "",
						'color': "#FFFFFF",
						'fontSize': "14px"
					},
					'selfServiceButtons': {
						'backgroundColor': 'black',
						'fontSize': '18px',
						'height': '',
						'color': 'white',
						'width': '170px'
					},
					'changePasswordButtonText': 'Update Password',
					'deleteAccountButtonText': 'Delete Account',
					'logoutButtonText': 'Logout',
				},
				'updatePasswordStep': {
					'updatePasswordButtonText': 'Change Password',
					'updatePasswordInput': {
						'placeholderOldPassword': 'Old Password',
						'placeholderNewPassword': 'New Password',
						'placeholderRepeatPassword': 'Repeat password'
					},
					'title': {
						'textContent': 'Update Password',
						'color': '#fff',
						'fontSize': '36px'
					},
					'subtitle': {
						'textContent': '',
						'color': '#fff',
						'fontSize': '14px'
					},
					'backToMenuLink': {
						'textContent': 'Back',
						'fontSize': '14px',
						'color': '#000'
					},
				},
				'localStorageBlockedPage': {
					'buttonText': 'Okay',
					'title': {
						'textContent': 'Registration Wall is not working.',
						'color': 'white',
						'fontSize': '36px',
					},
					'subtitle': {
						'textContent': 'Please enable third party cookies',
						'color': 'white',
						'fontSize': '14px'
					}
				}
			}
		},
		// options to show iframe is 'interval', 'siteVisits'
		'showIframeOptions': undefined,
		// if showIframeOptions is set to 'interval', property 'showInterval' needs to be set, mil-seconds
		'showIframeInterval': undefined,
		// if showIframeOptions is set to 'siteVisits' property 'showAmountSiteVisits' needs to be set, integer
		'showIframeAmountSiteVisits': undefined,
		'selfServiceMenuButton': {
			'displaySelfServiceMenu': true,
			'zIndex': 2147483646,
			'position': 'bottomRight',
			'desktopStyle': {
				'toggleWidth': '100',
				'toggleHeight': '50',
				'toggleBackground': 'black',
				'toggleBorderColor': 'white',
				'toggleBorderWidth': '5',
				'toggleTextValue': 'Logged in',
				'toggleTextFontFamily': 'Roboto;Arial;sans-serif',
				'toggleTextFontColor': 'white',
				'toggleTextFontSize': '12',
				'toggleTextXPosition': '25',
				'toggleTextYPosition': '30'
			},
			'mobileStyle': {
				'toggleWidth': '100',
				'toggleHeight': '50',
				'toggleBackground': 'black',
				'toggleBorderColor': 'white',
				'toggleBorderWidth': '5',
				'toggleTextValue': 'Logged in',
				'toggleTextFontFamily': 'Roboto;Arial;sans-serif',
				'toggleTextFontColor': 'white',
				'toggleTextFontSize': '12',
				'toggleTextXPosition': '25',
				'toggleTextYPosition': '30'
			}
		}
	}
};

module.exports = config;
