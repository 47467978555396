import {
	getFeatureFlag, removeFeatureFlag,
	shouldActivate,
	setFeatureFlag,
} from "./feature-toggle-util";

const FEATURE_FLAG = {
	SIGNED_UP: false,
	ACTIVE: false,
	CURRENT_THRESHOLD_PERCENTAGE: 100,
};

let _isEnabled = false;

export function isRegistrationWallEnabled() {
	return _isEnabled;
}

/**
 * Checks if the registration wall should be triggered or not by inspecting the activeThresholdPercentage option
 *
 * returns true if the wall should be displayed, otherwise false
 * @param featureToggleThreshold config.activeThresholdPercentage
 * @returns {boolean}
 */
export function evaluateFeatureToggle(thresholdPercentage) {
	// If activeThresholdPercentage config option is undefined, enable feature for all users
	if (thresholdPercentage == null) {
		removeFeatureFlag();
		return _isEnabled = true;
	}

	FEATURE_FLAG.CURRENT_THRESHOLD_PERCENTAGE = thresholdPercentage;

	const featureFlagString = getFeatureFlag();
	const shouldActivateFeature = shouldActivate(thresholdPercentage);

	// enable feature if featureFlag is not set and current user is in the active percentage
	// else check if activeThresholdPercentage is changed and if user has been registered
	// otherwise disable feature if featureFlag is not set and current user is not in the active percentage
	if (!featureFlagString && shouldActivateFeature) {
		activateFeatureToggle();
	} else if (featureFlagString) {
		const featureFlag = JSON.parse(featureFlagString);

		if (featureFlag.SIGNED_UP) {
			return _isEnabled = true;
		}

		if (thresholdPercentage === featureFlag.CURRENT_THRESHOLD_PERCENTAGE) {
			return _isEnabled = featureFlag.ACTIVE;
		} else {
			shouldActivateFeature ? activateFeatureToggle() : deactivateFeatureToggle();
		}
	} else {
		deactivateFeatureToggle();
	}

	return _isEnabled = shouldActivateFeature;
}

/**
 * Activates the feature that enables registration wall to be triggered.
 * If the isSignedUp flag is set to true, registration wall cannot be disabled
 *
 * @param isSignedUp specifies whether the user is signed up or not. If set to true, registration wall cannot be disabled
 */
export function activateFeatureToggle(isSignedUp = false) {
	FEATURE_FLAG.ACTIVE = true;
	FEATURE_FLAG.SIGNED_UP = isSignedUp;
	setFeatureFlag(JSON.stringify(FEATURE_FLAG));
}

/**
 * Deactivates the feature that disables registration wall to be triggered
 */
export function deactivateFeatureToggle() {
	FEATURE_FLAG.ACTIVE = false;
	setFeatureFlag(JSON.stringify(FEATURE_FLAG));
}
